import { createRoot } from "react-dom/client";
import React, {useCallback, useEffect, useState} from "react";
import {CalculatorRequest, CalculatorResponse} from "../interfaces/Calculator.model";
import {CalculatorConfig, CalculatorConfigGroup} from "./CalculatorConfigGroup";
import {CalculatorLoading} from "./CalculatorLoading";
import t from "../helpers/calculator_translations";
import {TableCalculatorForm} from "./TableCalculatorForm";
import {InfoPopup} from "./InfoPopup";

interface Window {
    locale?: string;
}

interface CalculatorProps {
    locale: string;
    calculatorConfig: CalculatorConfig;
    defaultSourceLanguage?: string|null
    defaultTargetLanguage?: string|null;
    defaultDiscipline?: string|null;
}

const TableCalculator = ({ locale, calculatorConfig, defaultSourceLanguage, defaultTargetLanguage, defaultDiscipline}: CalculatorProps) => {
    const defaultValues = {
        words: parseInt(Object.keys(calculatorConfig.wordsOptions)[0]),
        sourceLanguage: defaultSourceLanguage ?? t('calculator.source_language.default'),
        targetLanguage: defaultTargetLanguage ?? t('calculator.target_language.default'),
        discipline: defaultDiscipline ?? 1,
    } as CalculatorRequest;

    const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean|null>(null)

    const [words, setWords] = useState<number|null>(defaultValues.words);
    const [sourceLanguage, setSourceLanguage] = useState<string>(defaultValues.sourceLanguage);
    const [targetLanguage, setTargetLanguage] = useState<string>(defaultValues.targetLanguage);
    const [discipline, setDiscipline] = useState<number|null>(defaultValues.discipline);

    // State for request / response
    const [requestedData, setRequestedData] = useState<CalculatorRequest|null>(null);
    const [response, setResponse] = useState<CalculatorResponse>({
        ai: {},
        economy: {},
        excellent: {},
        express: {},
        context: {},
        postEditingAvailable: false,
        advantages: [],
        maxAdvantages: 0
    });

    const [aiAvailable, setAiAvailable] = useState<boolean>(false);

    useEffect(() => {
        setAiAvailable(response.ai.pricePerWord !== null);
    }, [response]);

    useEffect(() => {
        if (
            (requestedData?.words ?? 1) !== words ||
            (requestedData?.sourceLanguage ?? t('calculator.source_language.default')) !== sourceLanguage ||
            (requestedData?.targetLanguage ?? t('calculator.target_language.default')) !== targetLanguage ||
            (requestedData?.discipline ?? 1) !== discipline
        ) {
            setFormHasChanges(true);
        }
    }, [words, sourceLanguage, targetLanguage, discipline]);

    const request = () => {
        setLoading(true);
        setFormHasChanges(false);

        setRequestedData({
            words: words,
            sourceLanguage: sourceLanguage,
            targetLanguage: targetLanguage,
            discipline: discipline,
        });

        let requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                domein_id: calculatorConfig.domainId ?? 1,
                brontaal: sourceLanguage,
                doeltaal: targetLanguage,
                vakgebied_id: discipline,
                aantal_woorden: words,
            })
        };

        fetch('/calculator/request', requestOptions)
            .then(response => response.json())
            .then(data => setResponse(data))
            .then(() => setLoading(false));
    }

    const submit = () => {
        request();
    }

    useEffect(() => {
        request();
    }, [])

    return (
        <div className={"calculator__form container p-0"}>
            <div className={"row"}>
                <TableCalculatorForm
                    locale={locale}
                    calculatorConfig={calculatorConfig}
                    words={words}
                    setWords={setWords}
                    sourceLanguage={sourceLanguage}
                    setSourceLanguage={setSourceLanguage}
                    targetLanguage={targetLanguage}
                    setTargetLanguage={setTargetLanguage}
                    discipline={discipline}
                    setDiscipline={setDiscipline}
                    formHasChanges={formHasChanges}
                    submit={submit}
                />
            </div>

            <div className={"row"}>
                <div className={"col-12 mt-3 position-relative"} style={{ minHeight: "100px" }}>
                    { loading && (
                        <CalculatorLoading color={"light"} />
                    )}

                    { loading === false && (
                        <div className="table-responsive">
                            <table className="pricing table">
                                <thead>
                                <tr>
                                    <th></th>
                                    { aiAvailable && (
                                        <th className={"fw-bold text-center"}>{response.ai.heading}</th>
                                    )}
                                    <th className={"fw-bold text-center"}>{response.economy.heading}</th>
                                    <th className={"fw-bold text-center"}>{response.excellent.heading}</th>
                                    <th className={"fw-bold text-center"}>{response.express.heading}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-nowrap"}>
                                        {t('calculator.word_price')}
                                        {requestedData?.words && (
                                            <> ({t('calculator.word_price_per_x_words', undefined, {wordCount: calculatorConfig.wordsOptions[requestedData?.words]})})</>
                                        )}
                                    </td>
                                    { aiAvailable && (
                                        <td className={"text-center text-nowrap"}>
                                            {response.ai.pricePerWord ? (
                                                new Intl
                                                    .NumberFormat(locale, {style: 'currency', currency: 'EUR'})
                                                    .format(response.ai.pricePerWord)
                                            ) : <>{t('calculator.price.in_consultation')}</>}
                                        </td>
                                    )}
                                    <td className={"text-center text-nowrap"}>
                                        {response.economy.pricePerWord ? (
                                            new Intl
                                                .NumberFormat(locale, {style: 'currency', currency: 'EUR'})
                                                .format(response.economy.pricePerWord)
                                        ) : <>{t('calculator.price.in_consultation')}</>}
                                    </td>
                                    <td className={"text-center text-nowrap"}>
                                        {response.excellent.pricePerWord ? (
                                            new Intl
                                                .NumberFormat(locale, {style: 'currency', currency: 'EUR'})
                                                .format(response.excellent.pricePerWord)
                                        ) : <>{t('calculator.price.in_consultation')}</>}

                                    </td>
                                    <td className={"text-center text-nowrap"}>
                                        {response.express.pricePerWord ? (
                                            new Intl
                                                .NumberFormat(locale, {style: 'currency', currency: 'EUR'})
                                                .format(response.express.pricePerWord)
                                        ) : <>{t('calculator.price.in_consultation')}</>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-nowrap"}>{t('calculator.price.minimum')}</td>
                                    { aiAvailable && (
                                        <td className={"text-center text-nowrap"}>
                                            {new Intl
                                                .NumberFormat(locale, {style: 'currency', currency: 'EUR'})
                                                .format(40)}
                                        </td>
                                    )}
                                    <td className={"text-center text-nowrap"}>
                                        {new Intl
                                            .NumberFormat(locale, {style: 'currency', currency: 'EUR'})
                                            .format(40)}
                                    </td>
                                    <td className={"text-center text-nowrap"}>
                                        {new Intl
                                            .NumberFormat(locale, {style: 'currency', currency: 'EUR'})
                                            .format(50)}
                                    </td>
                                    <td className={"text-center text-nowrap"}>
                                        {new Intl
                                            .NumberFormat(locale, {style: 'currency', currency: 'EUR'})
                                            .format(50)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-nowrap"}>
                                        {response.ai.welcomeDiscount ?? response.economy.welcomeDiscount ?? response.excellent.welcomeDiscount ?? response.express.welcomeDiscount}%
                                        {' '}
                                        {t('calculator.welcome_discount')}
                                        <InfoPopup text={response.context.welcomeDiscountInfoText ?? ''}/><br/>
                                    </td>
                                    { aiAvailable && (
                                        <td className={"text-center text-nowrap"}>
                                            {response.ai.welcomeDiscount && (
                                                <em className={"fa " + ("text-success fa-check")}></em>
                                            )}
                                        </td>
                                    )}
                                    <td className={"text-center text-nowrap"}>
                                        {response.economy.welcomeDiscount && (
                                            <em className={"fa " + ("text-success fa-check")}></em>
                                        )}
                                    </td>
                                    <td className={"text-center text-nowrap"}>
                                        {response.excellent.welcomeDiscount && (
                                            <em className={"fa " + ("text-success fa-check")}></em>
                                        )}
                                    </td>
                                    <td className={"text-center text-nowrap"}>
                                        {response.express.welcomeDiscount && (
                                            <em className={"fa " + ("text-success fa-check")}></em>
                                        )}
                                    </td>
                                </tr>
                                {response.advantages
                                    // Filter out advantages that have no checkmark in any of the categories
                                    .filter((advantage) => (aiAvailable && advantage.ai) || advantage.economy || advantage.express || advantage.excellent)
                                    .map((advantage, index) => (
                                        <tr key={index}>
                                            <td className={"text-nowrap"}>
                                                {advantage.usp}
                                                {advantage.infoText && (
                                                    <InfoPopup text={advantage.infoText}/>
                                                )}
                                            </td>
                                            { aiAvailable && (
                                                <td className={"text-center"}>
                                                    {advantage.ai && (
                                                        <em className={"fa " + ("text-success fa-check")}></em>
                                                    )}
                                                </td>
                                            )}
                                            <td className={"text-center"}>
                                                {advantage.economy && (
                                                    <em className={"fa " + ("text-success fa-check")}></em>
                                                )}
                                            </td>
                                            <td className={"text-center"}>
                                                {advantage.excellent && (
                                                    <em className={"fa " + ("text-success fa-check")}></em>
                                                )}
                                            </td>
                                            <td className={"text-center"}>
                                                {advantage.express && (
                                                    <em className={"fa " + ("text-success fa-check")}></em>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

let tableCalculatorElements = document.querySelectorAll('.table-calculator-component') as NodeListOf<HTMLElement>;
tableCalculatorElements.forEach((element) => {
    const locale: string = (window as Window).locale ?? 'nl';
    const calculatorConfig = CalculatorConfigGroup(locale);
    const root = createRoot(element);

    root.render(
        <TableCalculator
            locale={locale}
            calculatorConfig={calculatorConfig}
            defaultSourceLanguage={ element.getAttribute('data-source-language')}
            defaultTargetLanguage={ element.getAttribute('data-target-language')}
            defaultDiscipline={ element.getAttribute('data-discipline')}
        />
    );
});