import React, {useEffect} from "react"

// @ts-ignore
import t from "../helpers/calculator_translations";

interface WordsSelectorProps {
    words: number|null;
    wordsOptions: { [key: number]: string };
    action: (value: number) => void;
}
export const WordsSelector = ({words, wordsOptions, action}: WordsSelectorProps): JSX.Element => {

    return  <select
        className={"form-control custom-select"}
        required
        onChange={ (e) => {
            action(parseInt(e.target.value))
        }}
        style={{ height: "auto"}}
        value={words ?? ""}
    >
        {
            Object.entries(wordsOptions).map(([key, value]) => {
                return (
                    <option key={key} value={key}>{ value }</option>
                )
            })
        }
    </select>
}