import {LanguageSelector} from "./LanguageSelector";
import {DisciplineSelector} from "./DisciplineSelector";
import {useEffect} from "react";
import React from "react";

// @ts-ignore
import t from "../helpers/calculator_translations";
import {WordsSelector} from "./WordsSelector";
import {CalculatorConfig} from "./CalculatorConfigGroup";

interface CalculatorFormProps {
    locale: string;
    calculatorConfig: CalculatorConfig;
    words: number|null;
    setWords: (value: number) => void;
    sourceLanguage: string;
    setSourceLanguage: (value: string) => void;
    targetLanguage: string;
    setTargetLanguage: (value: string) => void;
    discipline: number|null;
    setDiscipline: (value: number) => void;
    formHasChanges: boolean;
    submit: () => void;
}
export const TableCalculatorForm = ({
    locale,
    calculatorConfig,
    words,
    setWords,
    sourceLanguage,
    setSourceLanguage,
    targetLanguage,
    setTargetLanguage,
    discipline,
    setDiscipline,
    submit
}: CalculatorFormProps): JSX.Element => {
    const swapLanguages = () => {
        let originalSourceLanguage = sourceLanguage;

        setSourceLanguage(targetLanguage);
        setTargetLanguage(originalSourceLanguage);
    }

    useEffect(() => {
        submit()
    }, [sourceLanguage, targetLanguage, discipline, words])

    return <div className={"col-12 d-flex flex-wrap flex-sm-nowrap justify-content-between"} style={{ columnGap: "0.5rem"}}>
        <div className={"search-term pricing-table-term"}>
            <label className={"sublabel text-nowrap"}>{ t("calculator.language_combination") }</label>
            <div className={"d-flex subcontent align-items-baseline"}>
                <div className={"d-flex me-2 flex-grow-1"}>
                    <LanguageSelector
                        locale={locale}
                        name={"source_language"}
                        action={setSourceLanguage}
                        value={sourceLanguage}
                    />
                </div>
                <span className={"me-2"} onClick={swapLanguages} style={{ cursor: "pointer"}}>
                    <svg style={{height: "14px", width: "14px"}}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M406.6 374.6l96-96c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224l-293.5 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288l293.5 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"/>
                    </svg>
                </span>
                <div className={"d-flex flex-grow-1"}>
                    <LanguageSelector
                        locale={locale}
                        name={"target_language"}
                        action={setTargetLanguage}
                        value={targetLanguage}
                    />
                </div>
            </div>
        </div>

        <div className={"search-term pricing-table-term"}>
            <label className={"sublabel"}>{ t("calculator.disciplines") }</label>
            <div className={"subcontent"}>
                <DisciplineSelector
                    discipline={discipline}
                    action={setDiscipline}
                />
            </div>
        </div>

        <div className={"search-term pricing-table-term"}>
            <label className={"sublabel"}>{ t("calculator.word_count") }</label>
            <div className={"subcontent"}>
                <WordsSelector
                    wordsOptions={calculatorConfig.wordsOptions}
                    words={words}
                    action={setWords}
                />
            </div>
        </div>
    </div>
}